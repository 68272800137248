/**
 * Font Size
 */

@if $use-font-size {

  .text-xs {
    font-size: $font-size-xs;
  }
  .text-sm {
    font-size: $font-size-sm;
  }
  .text-base {
    font-size: $font-size-base;
  }
  .text-lg {
    font-size: $font-size-lg;
  }
  .text-xl {
    font-size: $font-size-xl;
  }
  .text-xxl {
    font-size: $font-size-xxl;
  }

}
