/**
 * Aspect Ratio
 */

@if $use-aspect-ratio {

  .ratio-4-3 {
    @include aspect-ratio(4, 3);
  }
  .ratio-16-9 {
    @include aspect-ratio(16, 9);
  }

}
