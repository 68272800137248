/**
 * Aspect Ratio
 * Usage:
   @include aspect-ratio(16,9);
   @include aspect-ratio(4,3);
   @include aspect-ratio(4,4, true);
 */

@mixin aspect-ratio($x,$y, $pseudo: false) {
  $padding: unquote(($y / $x) * 100 + '%');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @if $pseudo {
    &:before {
      content: "";
      display: block;
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}
