/**
 * Echo Dot
 */

.echodot {
  width: 100%;
  min-height: 350px;
  background-image: url('../assets/img/echodot.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
  padding-top: 165px;
}

.sample-text {
  width: 100%;
  font-size: 7em;
  padding: 0 20%;
  text-align: center;
  font-family: $font-family-7segment;
  max-height: 168px;
  overflow: hidden;
  color: $color-white;
  opacity: .7;
  background-color: transparent;
  border: none;
  text-shadow: $color-white 0 0 2px; // light glow
  letter-spacing: 8px;
}

.alexaletters {
  font-family: $font-family-7segment;
  font-size: 1.5em;
  width: 100%;
  background-color: transparent;
  border: none;
  text-shadow: $color-white 0 0 2px; // light glow
}

.code,
.documentation {
  margin-top: 50px;
}

.monospace {
  font-family: $font-family-mono;
}
