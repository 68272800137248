/**
 * Clearfix
 *
 * Usage:
 * @include clearfix;
 */

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
