/**
 * Font Face
 */

@font-face {
  font-family: 'alexa_7_segment_displstandard';
  src:
    url('../assets/fonts/segment7/alexa7segmentdisplay-standard-webfont.woff2') format('woff2'),
    url('../assets/fonts/segment7/alexa7segmentdisplay-standard-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
