/**
 * Colors
 */

// Default text color
$color-text: #222 !default;

// Theme colors
$color-primary: #007bff !default;
$color-secondary: #7f19e6 !default;
$color-white: #fff !default;
$color-light: #f8f9fa !default;
$color-dark: #343a40 !default;
$color-black: #000 !default;
$color-success: #00b300 !default;
$color-error: #c00 !default;

$colors: (
  "default":   $color-text,
  "primary":   $color-primary,
  "secondary": $color-secondary,
  "white":     $color-white,
  "light":     $color-light,
  "dark":      $color-dark,
  "black":     $color-black,
  "success":   $color-success,
  "error":     $color-error
) !default;

// Links
$color-link-color: #007bff !default;
$color-link-color-hover: darken($color-link-color, 20%) !default;
